import * as actionTypes from "./actionTypes";

/**
 * Returns AUTH_START action
 * @function authStart
 */
export const authStart = () => {
	return {
		type: actionTypes.AUTH_START,
	};
};

export const authClearError = () => {
	return {
		type: actionTypes.CLEAR_AUTH_ERROR_STATE,
	};
};

/**
 * Returns authSuccess function in reducer
 * @function authSuccess
 * @param {string} token - authSuccess.
 * @returns {function} - Reducer function.
 */
export const authSuccess = (token) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		token: token,
	};
};

export const clearTwoFactorLoginError = () => {
	return {
		type: actionTypes.CLEAR_TWO_FACTOR_LOGIN_ERROR,
	};
};

export const clearTwoFactorLoginData = () => {
	return {
		type: actionTypes.CLEAR_TWO_FACTOR_LOGIN_DATA,
	};
};

export const twoFactorAuthSuccess = (
	twoFactorStatus,
	twoFactorQRLink,
	twoFactorUserEmail
) => {
	return {
		type: actionTypes.TWO_FACTOR_AUTH_SUCCESS,
		twoFactorStatus,
		twoFactorQRLink,
		twoFactorUserEmail,
	};
};

/**
 * Returns authFail function in reducer
 * @function authFail
 * @param {string} error - authFail.
 * @returns {function} - Reducer function.
 */
export const authFail = (error) => {
	return {
		type: actionTypes.AUTH_FAIL,
		error: error,
	};
};

/**
 * Returns Redux Saga function that dispatches AUTH_INITIATE_LOGOUT action
 *     and (conditionally) AUTH_INITIATE_LOGOUT action
 * @function logout
 * @returns {function} - Redux Saga function.
 */
export const logout = () => {
	return {
		type: actionTypes.AUTH_INITIATE_LOGOUT,
	};
};

/**
 * Returns Redux Saga function that dispatches AUTH_LOGOUT action
 *     and (conditionally) AUTH_LOGOUT action
 * @function logoutSucceed
 * @returns {function} - Redux Saga function.
 */
export const logoutSucceed = () => {
	return {
		type: actionTypes.AUTH_LOGOUT,
	};
};

/**
 * Returns Redux Saga function that dispatches AUTH_CHECK_TIMEOUT action
 *     and (conditionally) AUTH_CHECK_TIMEOUT action
 * @function checkAuthTimeout
 * @param {string} expirationTime - checkAuthTimeout.
 * @returns {function} - Redux Saga function.
 */
export const checkAuthTimeout = (expirationTime) => {
	return {
		type: actionTypes.AUTH_CHECK_TIMEOUT,
		expirationTime: expirationTime,
	};
};

/**
 * Returns Redux Saga function that dispatches AUTH_USER_LOGIN action
 *     and (conditionally) AUTH_USER_LOGIN action
 * @function auth
 * @param {string} email password - auth.
 * @returns {function} - Redux Saga function.
 */
export const auth = (email, password) => {
	return {
		type: actionTypes.AUTH_USER_LOGIN,
		email: email,
		password: password,
	};
};
export const adminLogin = (credentials) => {
	return {
		type: actionTypes.ADMIN_LOGIN,
		credentials,
	};
};

export const adminLoginSuccess = (response) => {
	return {
		type: actionTypes.ADMIN_LOGIN_SUCCESS,
		response,
	};
};

export const clearAdminLogin = () => {
	return {
		type: actionTypes.CLEAR_ADMIN_LOGIN,
	};
};

/**
 * Returns Redux Saga function that dispatches AUTH_CHECK_STATE action
 * and (conditionally) AUTH_CHECK_STATE action
 * @function authCheckState
 * @returns {function} - Redux Saga function.
 */
export const authCheckState = () => {
	return {
		type: actionTypes.AUTH_CHECK_STATE,
	};
};

export const setAlert = (response) => {
	return {
		type: actionTypes.SHOW_ALERT,
		response,
	};
};

export const socialMediaSignup = (data) => {
	return {
		type: actionTypes.AUTH0_DATA_POST,
		data,
	};
};

export const clearResendEmailVerification = () => {
	return {
		type: actionTypes.CLEAR_RESEND_EMAIL_VERIFICATION_LINK,
	};
};

export const resendEmailVerification = (email) => {
	return {
		type: actionTypes.RESEND_EMAIL_VERIFICATION_LINK,
		email: email,
	};
};

export const resendEmailVerificationSuccess = (response) => {
	return {
		type: actionTypes.RESEND_EMAIL_VERIFICATION_LINK_SUCCESS,
		response,
	};
};

export const resendEmailVerificationFail = (response) => {
	return {
		type: actionTypes.RESEND_EMAIL_VERIFICATION_LINK_FAIL,
		response,
	};
};

// Admin Two Factor
export const clearMFALoginError = () => {
	return {
		type: actionTypes.CLEAR_MFA_LOGIN_ERROR,
	};
};

export const clearMFALoginData = () => {
	return {
		type: actionTypes.CLEAR_MFA_LOGIN_DATA,
	};
};

export const getMFAAuthSuccess = (response) => {
	return {
		type: actionTypes.MFA_AUTH_SUCCESS,
		response,
	};
};

export const verifyMFA = (email, mfaCode) => {
	return {
		type: actionTypes.VERIFY_TWO_FACTOR_AUTH,
		email,
		mfaCode,
	};
};

export const verifyMFAError = (error) => {
	return {
		type: actionTypes.VERIFY_TWO_FACTOR_AUTH_ERROR,
		error,
	};
};
